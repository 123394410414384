
import { mapMutations, mapState } from 'vuex';
import Post from '@/components/post.vue';
import Page from '@/components/page.vue';

export default {
  name: 'PageOrPost',

  components: {
    Page,
    Post,
  },

  async fetch() {
    const slug = this.$route.params.slug;
    try {
      if (
        this.$route.path === '/' ||
        slug === 'index.html' ||
        slug === undefined
      ) {
        const { data } = await this.$wp.frontpage();
        if (data) {
          this.page = { ...data };
          this.isPost = false;
        }
      } else {
        const { data } = await this.$wp.page({ slug });
        if (data) {
          this.page = { ...data };
          this.isPost = data.type === 'post';
        } else {
          return this.$nuxt.error({ statusCode: 404 });
        }
      }
    } catch (e) {
      return this.$nuxt.error({ statusCode: e.response.status });
    }
  },

  data() {
    return {
      isPost: false,
      page: null,
    };
  },

  computed: {
    ...mapState(['patientId']),
  },

  mounted() {
    this.setIsPost(this.isPost);
    this.$bolev.metaEvent({
      patientId: this.patientId,
      type: 'pageView',
    });
  },

  methods: {
    ...mapMutations(['setIsPost']),
  },

  head() {
    if (this.page) {
      return this.$seo.yoast(this.page.yoast_head_json);
    }
  },
};
